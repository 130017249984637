import Calendar from "components/Calendar/Calendar";
import Main from "components/Main/Main";

const Schedule = () => {
  return (
    <Main title="Calendar">
      <Calendar />
    </Main>
  );
};

export default Schedule;
