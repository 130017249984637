import React from "react";
import Main from "../../components/Main/Main";
import { DragDropContext, DropResult } from "@hello-pangea/dnd";
import { Grid } from "@mui/material";
import Column from "../../components/KanbanColumn/KanbanColumn";
import { getTasks, getTasksColumns, updateTask } from "../../api/tasks";
import lodash from "lodash";
import { getProjects } from "../../api/projects";
import { ProjectProps } from "../Projects/Projects.types";
import { toast } from "react-toastify";
import { ColumnProps } from "./Tasks.types";
import { useUserData } from "contexts/UserProvider";

const Tasks = () => {
  const [columns, setColumns] = React.useState<ColumnProps[]>([]);
  const [projects, setProjects] = React.useState<ProjectProps[]>([]);

  const [user] = useUserData();

  React.useEffect(() => {
    const fetchAll = async () => {
      if (!user) {
        return;
      }
      const [columnsRes, tasksRes, projectsRes] = await Promise.all([
        getTasksColumns(user.token),
        getTasks(user.token),
        getProjects(user.token),
      ]);

      const grouppedTasks = lodash.groupBy(tasksRes, "table_id");

      const jointArrays = columnsRes.map((col) => {
        col.items = grouppedTasks[col.id] || [];
        return col;
      });

      setColumns(jointArrays);
      setProjects(projectsRes);
    };

    fetchAll().catch((error) => toast.error(error));
  }, [user]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !user) {
      return;
    }
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns.filter(
        (column) => column.id === source.droppableId,
      );
      const destColumn = columns.filter(
        (column) => column.id === destination.droppableId,
      );
      const sourceItems = [...sourceColumn][0].items;
      const destItems = [...destColumn][0].items;
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      const removedSourceColumn = columns.filter(
        (column) => column.id !== sourceColumn[0].id,
      );
      const removedDestColumn = removedSourceColumn.filter(
        (column) => column.id !== destColumn[0].id,
      );
      const joinColumnChanges = [
        sourceColumn,
        destColumn,
        removedDestColumn,
      ].flat();

      updateTask(user.token, draggableId, {
        column_id: destination.droppableId,
      })
        .then(() =>
          setColumns(joinColumnChanges.sort((a, b) => a.orderBy - b.orderBy)),
        )
        .catch((error) => toast.error("Update single field: " + error));
    } else {
      const sourceColumn = columns.filter(
        (column) => column.id == source.droppableId,
      );
      const copiedItems = [...sourceColumn][0].items;
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);

      const removedSourceColumn = columns.filter(
        (column) => column.id !== sourceColumn[0].id,
      );
      const joinColumnChanges = [sourceColumn, removedSourceColumn].flat();
      setColumns(joinColumnChanges.sort((a, b) => a.orderBy - b.orderBy));
    }
  };

  return (
    <Main title="Tasks">
      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          {columns.map((column) => {
            return (
              <Grid item key={column.id} xs={12} lg={4}>
                <Column
                  column={column}
                  columns={columns}
                  setColumns={setColumns}
                  projects={projects}
                />
              </Grid>
            );
          })}
        </Grid>
      </DragDropContext>
    </Main>
  );
};

export default Tasks;
